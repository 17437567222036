/* src/App.css */
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: all 0.3s ease;
}

.App.light {
  background-color: #f8f9fa;
  color: #212529;
}

.App.dark {
  background-color: #212529;
  color: #f8f9fa;
}

.App-header {
  max-width: 800px;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.light .App-header {
  background-color: white;
}

.dark .App-header {
  background-color: #343a40;
}

h1 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 2.5rem;
}

.counter-container {
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
}

.light .counter-container {
  background-color: #f8f9fa;
}

.dark .counter-container {
  background-color: #2b3035;
}

.counter-container p {
  font-size: 3rem;
  font-weight: bold;
  margin: 20px 0;
  transition: all 0.3s ease;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.light button {
  background-color: #e9ecef;
  color: #212529;
}

.dark button {
  background-color: #495057;
  color: #f8f9fa;
}

button:hover {
  transform: translateY(-2px);
}

.light button:hover {
  background-color: #dee2e6;
}

.dark button:hover {
  background-color: #6c757d;
}

.decrement {
  background-color: #dc3545 !important;
  color: white !important;
}

.reset {
  background-color: #6c757d !important;
  color: white !important;
}

.increment {
  background-color: #28a745 !important;
  color: white !important;
}

.theme-toggle {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.positive {
  color: #28a745;
}

.negative {
  color: #dc3545;
}

.counter-info {
  margin-top: 20px;
  font-style: italic;
}

@media (max-width: 600px) {
  .App-header {
    padding: 20px;
    width: 90%;
  }
  
  h1 {
    font-size: 1.8rem;
  }
  
  .counter-container p {
    font-size: 2.5rem;
  }
  
  .button-container {
    flex-direction: column;
    gap: 10px;
  }
}